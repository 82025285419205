import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Container, Accordion, Segment, Header } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'

import { getFluidImage } from '../utils/images'
import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'

const EntryRequirements = ({ data, t, pageContext }) => {
  const { firestoreImage: { cloudinary, transformation, title, alt }, allFirestoreCountry: { edges: countries } } = data

  const panels = countries.map(({ node: { id, title, entry } }) => ({
    key: id,
    title: { content: <Header style={{ display: 'inline-block', marginTop: 0 }} color='red' content={title} /> },
    content: { content: <p dangerouslySetInnerHTML={{ __html: entry.replace(/(\n)+/g, '<br />') }} /> }
  }))

  return (
    <div>
      <Helmet>
        <title>{t('entry-requirements.header')}</title>
        <meta name='description' content={t('entry-requirements.header')} />
      </Helmet>

      <Layout pageContext={pageContext}>
        <Img fluid={getFluidImage({ cloudinary, ar: 2.5, transformation })} alt={alt || title} />

        <PageHeader title={t('entry-requirements.header')} />

        <Segment vertical padded='very' basic>
          <Container text>
            <Segment vertical padded='very' basic>
              <Accordion panels={panels} fluid />
            </Segment>
          </Container>
        </Segment>
      </Layout>
    </div>
  )
}

export default withTranslation()(EntryRequirements)

export const pageQuery = graphql`
  query entryRequirementsQuery($lang: String!) {
    allFirestoreCountry(filter: { lang: { eq: $lang } }, sort: { fields: [title], order: ASC })  {
      edges {
        node {
          id
          title
          entry
          
        }
      }
    }
    firestoreImage(page: {eq: "entry"}) {
      cloudinary
      transformation
      title
      alt
    }
  }
`
